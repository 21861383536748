import { inject, Injectable } from '@angular/core';
import {
  AuthBackofficeLoginGQL,
  AuthBackofficeLoginMutationVariables
} from "../../../shared/graphql/generated/graphql";
import { map } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private loginGQL: AuthBackofficeLoginGQL,
  ) {}

  login(credentials: AuthBackofficeLoginMutationVariables['data']) {
    console.log('[AuthService] login', credentials);
    return this.loginGQL
      .mutate({ data: credentials })
      .pipe(
        map((res) => {
          return res.data?.managerAuthLogin
        })
      );
  }
}
